<template>
  <div class="app-background" :style="{ 'background-image': 'url(' + backgroundImage + ')' }">
    <div class="auth-form">
      <img alt="logo" class="logo" :src="logo" />
      <h3 class="heading">
        Sign up
      </h3>
      <a-form id="sign-up-form" layout="vertical" :model="form" :rules="validationRules()" name="signup" class="signup-form" @finish="onSubmit">
        <div class="row gx-4">
          <div class="col-sm-6">
            <a-form-item label="First Name" name="firstName">
              <a-input v-model:value="form.firstName" placeholder="Enter First Name" />
            </a-form-item>
          </div>

          <div class="col-sm-6">
            <a-form-item label="Last Name" name="lastName">
              <a-input v-model:value="form.lastName" placeholder="Enter Last Name" />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <a-form-item name="email" label="Email" autocomplete="off">
              <a-input v-model:value="form.email" placeholder="Enter email" />
            </a-form-item>
          </div>
        </div>

        <!-- <div class="row gx-4">
          <div class="col-sm-6">
            <a-form-item label="Password" name="password" autocomplete="off">
              <a-input-password v-model:value="form.password" placeholder="Password" @change="validPasswordPattern(form.password)" />
            </a-form-item>
            <div v-if="error" style="color:red;font-size:13px;word-wrap:break-word;">
              {{ passwordErrorMessage }}
            </div>
          </div>

          <div class="col-sm-6">
            <a-form-item label="Confirm Password" name="confirmPassword">
              <a-input v-model:value="form.confirmPassword" placeholder="Confirm Password" type="password" />
            </a-form-item>
          </div>
        </div> -->

        <div class="row gx-4">
          <div class="col-sm-6">
            <a-form-item label="Password" name="password" autocomplete="off">
              <a-input-password v-model:value="form.password" placeholder="Password" @change="validPasswordPattern(form.password)" />
            </a-form-item>
            <div v-if="this.errors.length">
              <span style="color:red" v-for="error in errors" :key="error">
                {{ error }}
              </span>
            </div>
          </div>

          <div class="col-sm-6">
            <a-form-item label="Confirm Password" name="confirmPassword">
              <a-input-password v-model:value="form.confirmPassword" placeholder="Confirm Password" type="password" />
            </a-form-item>
          </div>
        </div>

        <!-- Phone Number and Confirm Phone Number Row -->

        <!-- <div class="row gx-4">
          <div class="col-sm-6">
            <a-form-item label="Phone Number" name="mobile_number" autocomplete="off">
              <vue-tel-input v-model="form.mobile_number" mode="international" invalidMsg="Invalid phone number" :validCharactersOnly="true"></vue-tel-input>
            </a-form-item>
            <div v-if="this.mobile_numberErrors.length">
              <span style="color:red" v-for="error in mobile_numberErrors" :key="error">
                {{ error }}
              </span>
            </div>
          </div>

          <div class="col-sm-6">
            <a-form-item label="Confirm Phone Number" name="confirmmobile_number">
              <vue-tel-input v-model="form.confirmmobile_number" placeholder="Re-enter phone number" :validCharactersOnly="true" mode="international"></vue-tel-input>
            </a-form-item>
          </div>
        </div> -->

        <div class="buttons-w mt-2">
          <a-button type="primary" size="large" html-type="submit" class="btn-block">
            <span>Sign up</span>
          </a-button>
        </div>

        <div class="buttons-w form-link">
          <router-link to="/login">
            <small>Already have an account? Click to login</small>
          </router-link>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ref } from 'vue';
import { validUserName } from '../../utils/auth.js';
import { useRoute } from 'vue-router';
import AuthService from 'src/services/auth';

export default {
  setup() {
    const route = useRoute();
    return {
      route,
      validUserName,
      logo: require('src/assets/img/assembly-cp-logo.png'),
      backgroundImage: require('src/assets/img/backgroundImage.png'),
    };
  },
  inject: ['toast'],
  data() {
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        // mobile_number: ref(null),
        // confirmmobile_number: ref(null),
        // position: '-',
      },
      marketplaceToken:"",
      errors: [],
      mobile_numberErrors: [],
      passwordErrorMessage: "Choose stronger password with minimum 9 characters. Try a mix of a capital letter, 2 special characters and 2 numbers.",
      error: false,
    };
  },
  created(){
    const {marketplace_token} = this.route.query
    this.marketplaceToken=marketplace_token
  },
  methods: {
    ...mapActions(['userSignup']),
    async validPassword(_, password) {
      if (password && password === this.form.password) {
        return Promise.resolve();
      } else if (!this.form.password && !password) {
        return Promise.reject('Password confirmation is required');
      } else
        return Promise.reject('Confirm password must be equal to password');
    },
    isNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    async validmobile_number(_, mobile_number) {
      if (
        this.form.confirmmobile_number &&
        this.form.mobile_number === mobile_number
      ) {
        return Promise.resolve();
      } else {
        return Promise.reject('Phone Numbers must match');
      }
    },

  async validPasswordPattern(password) {
  this.error = false;
  if (password.length < 9) {
    this.error = true
  }
  if (!/[A-Z]/.test(password)) {
    this.error = true
  }
  if (!/(?=(.*[^a-zA-Z0-9]){2})/.test(password)) {
    this.error = true
  }
  if (!/\d{2}/.test(password)) {
    this.error = true
  }
  return Promise.resolve();
},

    validationRules() {
      return {
        firstName: [
          {
            required: true,
            validator: this.validUserName,
            trigger: 'submit',
          },
        ],
        lastName: [
          {
            required: true,
            validator: this.validUserName,
            trigger: 'submit',
          },
        ],
        email: [
          {
            required: true,
            type: 'email',
            trigger: 'submit',
            message: 'email is required',
          },
        ],
        password: [
          {
            required: true,
            min: 8,
            // validator: this.validPasswordPattern,
            trigger: 'submit',
            // message: 'Password should contain eight characters, at least one uppercase letter, one lowercase letter and one number',
          },
        ],
        confirmPassword: [
          { required: true, validator: this.validPassword, trigger: 'submit' },
        ],
        // mobile_number: [
        //   {
        //     required: true,
        //     trigger: 'submit',
        //     message: 'Mobile number is required',
        //   },
        // ],
        // confirmmobile_number: [
        //   {
        //     required: true,
        //     validator: this.validmobile_number,
        //     trigger: 'submit',
        //   },
        // ],
      };
    },
    async onSubmit() {
      console.log(this.marketplaceToken, this.route.query);
      
      const payloadMarketplace = {
        contactEmail: this.form?.email,
        contactPerson: this.form?.username + " " + this.form?.lastname,
        regToken: this.marketplaceToken,
      }
      const payload = {
        email: this.form.email,
        username: this.form.firstName,
        lastname: this.form.lastName,
        // position: this.form.position,
        password: this.form.password,
        confirm_password: this.form.confirmPassword,
        regToken:this.marketplaceToken,
        // mobile_number: this.form.mobile_number,
        // confirmmobile_number: this.form.confirmmobile_number,

      };
      const [error, data] = await AuthService.RegisterAWSUser(payload);
      
      console.log(error, data)
      if(data){
        this.toast.success("Your Account has been created. One of our Admin will approve it within 24 hours")
      }
      else{
        this.toast.error(error)
      }    
    },
  },
};
</script>
<style scoped>
.app-background {
  height: 100vh;
  background-size: cover;
  position: relative;
  background-position: center;
}

.app-background::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.auth-form {
  max-width: 530px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 16px;
  margin: auto;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  opacity: 0.9;
}

.logo {
  width: 250px;
  height: 60px;
  margin-bottom: 10px;
}

.heading {
  font-size: 1.4rem;
  margin-bottom: 14px;
}
.signup-form {
  width: 90%;
  margin-top: 20px;
}

.form-link {
  text-align: center;
  margin: 24px 0;
}
.form-link a {
  text-decoration: none;
  font-size: 16px;
}
.form-link a:hover {
  text-decoration: underline;
}
</style>
